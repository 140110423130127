import React from 'react';
import {ageRanges} from '../Utility/constants'
import AudioPlayer from '../Utility/AudioPlayer'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    NumberInput,
    DateField,
    ImageField,
    NumberField,
    ImageInput,
    Filter,
    AutocompleteInput,
    TabbedForm,
    FormTab,
    List,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    SelectArrayInput,
    FileInput,
    FileField,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

const MyFilter = (props) => (
    <Filter {...props}>
        <TextInput label="title/keyword/description" style={{minWidth: 250}} source="search" alwaysOn/>
        <ReferenceInput
            label="Program"
            source="programs"
            reference="programs"
            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty={true}
            alwaysOn
        >
            <AutocompleteInput optionText="title"/>
        </ReferenceInput>

        <ReferenceInput
            label="Course"
            source="courses"
            reference="courses"
            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty={true}
            alwaysOn
        >
            <AutocompleteInput optionText="title"/>
        </ReferenceInput>

        <ReferenceInput
            label="Age Ranges"
            source="ageRanges"
            reference="ageRanges"

            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty={true}
            alwaysOn
        >
            <SelectInput optionText="title"/>
        </ReferenceInput>

        <ReferenceInput
            label="Emotions"
            source="emotions"
            reference="emotions"

            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty={true}
            alwaysOn
        >
            <SelectInput optionText="title"/>
        </ReferenceInput>
    </Filter>
);


export const SessionsList = props => (
    <List {...props} exporter={false}  filters={<MyFilter />} perPage={500}>
        <Datagrid rowClick="edit">

            <DateField source="created"/>

            <ImageField source="media.image.url" label="Image"/>
            <TextField source="title"/>
w            <TextField  source="description"/>
            <BooleanField source="active"/>
            <TextField  source="keywords_CSV" label="Keywords"/>
            <NumberField source="orderIndex"/>


        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const SessionsEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <TabbedForm>

            <FormTab label="General">
                <NumberInput source="orderIndex"/>
                <BooleanInput source="active"/>
                <BooleanInput source="free"/>
                <TextInput source="title" fullWidth/>
                <TextInput source="description"  fullWidth/>
                <TextInput source="keywords_CSV" label="Keywords"  fullWidth/>
                <NumberInput source="calculatedSessionLength_s"/>
                <ReferenceArrayInput source="emotions" reference="emotions" perPage={100} label="Target Emotions" fullWidth>
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="programs" reference="programs" perPage={100} label="Programs" fullWidth>
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="courses" reference="courses" perPage={100} label="Courses" fullWidth>
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="ageRanges" reference="ageRanges" perPage={100} label="Child Ages" fullWidth>
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <SelectInput source="onboarding" label="Onboarding Type" choices={[
                    {id: 'preg_only', name: 'HOUSE PREG ONLY'},
                    {id: 'preg_kids', name: 'HOUSE PREG + KIDS'},
                    {id: '1_kid', name: 'HOUSE 1 KID ONLY'},
                    {id: 'many_kids', name: 'HOUSE MULTIPLE KIDS'},

                ]}/>



                <br/>

                <NumberInput source="meditation.length_s" label="Session Length"/>




                <ReferenceInput source="meditation.backgroundDay" reference="backgrounds" perPage={100} label="Day Background Music"
                                fullWidth>
                    <SelectInput optionText="title"/>
                </ReferenceInput>

                <br/>

                <NumberInput source="meditation.mainTrackVolumeMultiplier" label="Main Track Volume (default = 1.0)"/>


                <ReferenceInput source="meditation.backgroundNight" perPage={100} reference="backgrounds"
                                label="Night Background Music" fullWidth>
                    <SelectInput optionText="title"/>
                </ReferenceInput>




                <ImageField source="media.image.url" label="Current Image"/>

                <ImageInput source="picture" label="Replace Image" accept="image/*"
                            multiple={false}
                            placeholder={<p>Click here or Drop your file here</p>}
                >
                    <ImageField source="src" title="image_title" label="New Image"/>
                </ImageInput>

            </FormTab>
            <FormTab label="Male Audio">

                <ReferenceInput source="meditation.induction" reference="inductions" label="Induction" perPage={100} fullWidth>
                    <SelectInput optionText="title"/>
                </ReferenceInput>

                <hr fullWidth/>
                <br/>

                <AudioPlayer source="meditation.endingDay.audio.url" label="Day: "/>
                <FileInput source="audio.day" label="Replace Day Ending Audio" accept=".mp3">
                    <FileField source="src" title="title"/>
                </FileInput>
                <hr fullWidth/>
                <br/>

                <br/>
                <AudioPlayer source="meditation.endingNight.audio.url" label="Night: "/>
                <FileInput source="audio.night" label="Replace Night Ending Audio" accept=".mp3">
                    <FileField source="src" title="title"/>
                </FileInput>


            </FormTab>

            <FormTab label="Female Audio">
                <ReferenceInput source="meditation.inductionFemale" reference="inductions" label="Induction" perPage={100} fullWidth>
                    <SelectInput optionText="title"/>
                </ReferenceInput>

                <hr fullWidth/>
                <br/>

                <AudioPlayer source="meditation.endingDayFemale.audio.url" label="Day: "/>
                <FileInput source="audio.dayFemale" label="Replace Day Ending Audio" accept=".mp3">
                    <FileField source="src" title="title"/>
                </FileInput>
                <hr fullWidth/>
                <br/>

                <br/>
                <AudioPlayer source="meditation.endingNightFemale.audio.url" label="Night: "/>
                <FileInput source="audio.nightFemale" label="Replace Night Ending Audio" accept=".mp3">
                    <FileField source="src" title="title"/>
                </FileInput>


            </FormTab>



        </TabbedForm>
    </Edit>
);

export const SessionsCreate = props => (
    <Create {...props}>
        <TabbedForm>

            <FormTab label="General">
                <BooleanInput source="active"/>
                <BooleanInput source="free"/>
                <TextInput validate={required()} source="title"/>
                <TextInput source="description"  fullWidth/>

                <ReferenceArrayInput source="emotions" reference="emotions" label="Target Emotions" perPage={100} fullWidth>
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>
                <ReferenceArrayInput source="programs" reference="programs" label="Programs" perPage={100} fullWidth>
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="ageRanges" reference="ageRanges" label="Child Ages" perPage={100} fullWidth>
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>



            </FormTab>

            <FormTab label="Audio">

                <br/>
                <ReferenceInput source="meditation.induction" reference="inductions" label="Induction" fullWidth>
                    <SelectInput optionText="title"/>
                </ReferenceInput>

                <hr fullWidth/>
                <br/>
                <FileInput source="audio.day" label="Day Ending Audio" accept=".mp3">
                    <FileField source="src" title="title"/>
                </FileInput>
                <br/>
                <ReferenceInput source="meditation.backgroundDay" reference="backgrounds" label="Day Background Music"
                                fullWidth>
                    <SelectInput optionText="title"/>
                </ReferenceInput>
                <hr fullWidth/>
                <br/>
                <FileInput source="audio.night" label="Night Ending Audio" accept=".mp3">
                    <FileField source="src" title="title"/>
                </FileInput>

                <br/>
                <ReferenceInput source="meditation.backgroundNight" reference="backgrounds"
                                label="Night Background Music" fullWidth>
                    <SelectInput optionText="title"/>
                </ReferenceInput>
            </FormTab>
            <FormTab label="Image">
                <ImageField source="media.image.url" label="Current Image"/>

                <ImageInput source="picture" label="Replace Image" accept="image/*"
                            multiple={false}
                            placeholder={<p>Click here or Drop your file here</p>}
                >
                    <ImageField source="src" title="title" label="New Image"/>
                </ImageInput>

            </FormTab>

        </TabbedForm>
    </Create>
);
